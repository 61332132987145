<template>
  <div class="form-step form-step-two">
    <div class="q-gutter-md">
      <div>
        <q-radio
          v-for="(option, i) in partnerTypes"
          :key="i"
          v-model="partnerType"
          :val="option.value"
          :label="option.label"
          color="pink-4"
        />
      </div>

      <template v-for="option in partnerTypes">
        <template v-if="partnerType == option.value">
          <div
            :key="z"
            v-for="(field, z) in getFieldByType(stepFields, option.value)"
          >
            <FormInputComponent
              :stepInput="field"
              @inputChange="(value) => handleInputChange(value, field.index)"
            />
          </div>
        </template>
      </template>

      <template v-if="partnerType == 'pj'">
        <FormCnaesComponent @getCnaes="setCnaes" />
      </template>
    </div>
    <FormActionsComponent
      :step="2"
      class="form-actions"
      @return-form="handleReturn"
      @next-step="handleStepData"
    />
  </div>
</template>

<script setup>
import {
  formatData,
  partnerTypes,
  getFormFieldsByStep,
} from "../form-config/index.js";
import FormInputComponent from "../FormInputComponent.vue";
import FormCnaesComponent from "../FormCnaesComponent.vue";
import FormActionsComponent from "../FormActionsComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import { onMounted, defineProps, watch, ref, defineEmits } from "vue";

const { useActions } = createNamespacedHelpers("auth");

const emit = defineEmits([
  "save-form",
  "return-form",
  "set-selected-partner-type",
]);

const props = defineProps({
  selectedPartnerType: {
    type: String,
    default: "pf",
  },
});

const { signUpFlipPartnerTypeAction } = useActions([
  "signUpFlipPartnerTypeAction",
]);

const cnaes = ref([]);
const partnerType = ref("pf");
const stepFields = ref(getFormFieldsByStep(2).fields);

function handleStepData() {
  if (partnerType.value === "pj" && cnaes.value.length === 0) {
    notifyError("Selecione ao menos um CNAE.");
    return;
  }

  const formattedData = formatData(stepFields.value);

  emit("save-form", {
    ...formattedData,
    ...(partnerType.value === "pj" ? { pj_cnaes: cnaes.value } : {}),
  });
}

function handleInputChange(newValue, index) {
  stepFields.value[index].value = newValue.value;
}

function getFieldByType(fields, type) {
  return fields.filter((e) => e.group === type);
}

function setCnaes(emittedCnaes) {
  cnaes.value = emittedCnaes;
}

function handleReturn() {
  emit("return-form");
}

watch(partnerType, (value) => {
  signUpFlipPartnerTypeAction(value);
  emit("set-selected-partner-type", value);
});

onMounted(() => {
  partnerType.value = props.selectedPartnerType;
});
</script>
