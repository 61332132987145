<template>
  <div class="form-step form-step-one">
    <div class="q-gutter-md">
      <div v-for="(field, index) in stepFields" :key="index">
        <FormInputComponent
          :stepInput="field"
          @inputChange="(value) => handleInputChange(value, index)"
        />
      </div>
      <div>
        <q-checkbox
          v-model="optIn"
          :label="$t('signup.opt_in')"
          color="pink-4"
        />
        <q-checkbox
          v-model="terms"
          :label="$t('signup.use_terms')"
          color="pink-4"
        />
        <p
          style="font-size: 8pt"
          class="text-justify q-mt-sm"
          v-html="$t('signup.help_message')"
        />
      </div>
    </div>
    <FormActionsComponent class="form-actions" :step="1" @next-step="handleStepData" />
  </div>
</template>

<script setup>
import { getFormFieldsByStep, formatData } from "../form-config/index.js";
import FormInputComponent from "../FormInputComponent.vue";
import FormActionsComponent from "../FormActionsComponent.vue";
import { notifyError } from "@/shared/helpers/notify";
import { ref, defineEmits } from "vue";
import { uuid } from "uuidv4";

const emit = defineEmits(["save-form"]);

const stepFields = ref(getFormFieldsByStep(1).fields);
const optIn = ref(true);
const terms = ref(true);

function handleStepData() {
  if (!optIn.value || !terms.value) {
    notifyError("Concorde com os termos.");
    return;
  }

  const formattedData = formatData(stepFields.value);

  const data = {
    ...formattedData,
    slug_parent: "flipnet",
    opt_in: optIn.value,
    password: uuid(),
  };

  emit("save-form", data);
}

function handleInputChange(newValue, index) {
  stepFields.value[index].value = newValue.value;
}
</script>
