<template>
  <div class="form-step form-step-three">
    <div class="q-gutter-md">
      <div :key="index" v-for="(field, index) in stepFields">
        <FormInputComponent
          :stepInput="field"
          @inputChange="(value) => handleInputChange(value, index)"
        />
      </div>
    </div>
    <FormActionsComponent
    :step="3"
    class="form-actions"
    @return-form="handleReturn"
    @next-step="handleStepData"
  />
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { formatData, getFormFieldsByStep } from "../form-config/index.js";
import FormActionsComponent from "../FormActionsComponent.vue";
import FormInputComponent from "../FormInputComponent.vue";

const emit = defineEmits(["save-form", "return-form"]);

const stepFields = ref(getFormFieldsByStep(3).fields);

function handleStepData() {
  const formattedData = formatData(stepFields.value);
  emit("save-form", formattedData);
}

function handleInputChange(newValue, index) {
  stepFields.value[index].value = newValue.value;
}

function handleReturn() {
  emit("return-form");
}
</script>
