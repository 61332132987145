<template>
  <q-card
    :class="{
      'form-step-two': step == 2 && getSignupFlipPartnerType == 'pj',
      form: step != 2 || getSignupFlipPartnerType == 'pf',
    }"
  >
    <p class="flex justify-start items-center btn-my-account">
      <span> Você já possui uma conta? </span>
      <a
        style="color: #000"
        class="anchor-link-external"
        target="_blank"
        :href="baseFrontUrl + '/flipnet/login'"
        rel="noopener noreferrer"
        >Entrar</a
      >
    </p>
    <hr class="q-my-md" />
    <FormTopComponent :step="step" />
    <q-form @submit="onSubmit" greedy>
      <q-card-section>
        <component
          @returnForm="step--"
          @saveForm="handlePayload"
          :userEmail="payload.email"
          :is="getFormStepComponent(step)"
          :selectedPartnerType="selectedPartnerType"
          @setSelectedPartnerType="selectedPartnerType = $event"
        />
      </q-card-section>
    </q-form>
  </q-card>
</template>

<script setup>
import FormStepThreeComponent from "./steps/FormStepThreeComponent.vue";
import FormStepTwoComponent from "./steps/FormStepTwoComponent.vue";
import FormStepOneComponent from "./steps/FormStepOneComponent.vue";
import FormSuccessComponent from "./steps/FormSuccessComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import FormTopComponent from "./FormTopComponent.vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { baseFrontUrl } from "@/shared/http/api";
import { onMounted, ref, defineProps } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("auth");
const { fetchBlogSignUpSkeleton, storeSignUp } = useActions([
  "fetchBlogSignUpSkeleton",
  "storeSignUp",
]);

const { getSignupFlipPartnerType } = useGetters(["getSignupFlipPartnerType"]);

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const step = ref(1);
const selectedPartnerType = ref("pf");
const payload = ref({});

const props = defineProps({
  utmParams: {
    type: Object,
    required: true,
  },
});

function handlePayload(stepData) {
  payload.value = { ...payload.value, ...stepData };
}

function filterByType(obj, type) {
  const pfFields = ["pf_cpf", "pf_nascimento"];
  const pjFields = ["pj_cnpj", "pj_fantasia", "pj_pf_cpf", "pj_cnaes"];

  let allowedFields;

  if (type === "pf") {
    allowedFields = pfFields;
  } else if (type === "pj") {
    allowedFields = pjFields;
  } else {
    throw new Error("Invalid type. Must be 'pf' or 'pj'.");
  }

  // Keep all non-specific fields and filter out the unwanted specific fields
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      console.log(value);
      return (
        (!key.startsWith("pf_") && !key.startsWith("pj_")) ||
        allowedFields.includes(key)
      );
    })
  );

  if (type === "pj") {
    filteredObj.pf_cpf = filteredObj.pj_pf_cpf;
    delete filteredObj.pj_pf_cpf;
  }

  return filteredObj;
}

async function onSubmit() {
  if (step.value < 3) step.value++;
  else {
    await recaptchaLoaded();
    let recaptchaToken = await executeRecaptcha("login");
    let filteredData = filterByType(payload.value, selectedPartnerType.value);

    storeSignUp({
      ...filteredData,
      password_recovery: true,
      pf_pj: selectedPartnerType.value,
      recaptcha_response: recaptchaToken,
      utm_source: props.utmParams.utm_source,
      utm_medium: props.utmParams.utm_medium,
      utm_campaign: props.utmParams.utm_campaign,
    })
      .then(() => step.value++)
      .catch(() => notifyError("Erro ao cadastrar usuário."));
  }
}

const getFormStepComponent = (stepId) => {
  const stepComponents = {
    1: FormStepOneComponent,
    2: FormStepTwoComponent,
    3: FormStepThreeComponent,
    4: FormSuccessComponent,
  };

  return stepComponents[stepId] || null;
};

onMounted(() => fetchBlogSignUpSkeleton("flipnet"));
</script>

<style lang="scss" scoped>
.form,
.form-step-two {
  border-radius: 20px;
}
.form {
  max-height: 850px;
}
.form-step-two {
  max-height: 1050px;
}
</style>
